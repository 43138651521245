<template>
  <v-container fluid class="mt-0 pa-1">
    VENTA

    <v-btn icon color="info" @click="dialogSetFrom()" v-if="tipo != 'ver'">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <p>Monto: {{ currencyFormat(totalOption) }}</p>

    <v-expansion-panels
      class="condensed my-1"
      v-if="
        services.filter((v) => v.codegroupservices == 4).length > 0
          ? services.filter((v) => v.codegroupservices == 4)[0].status
          : false
      "
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Flete y Gastos de origen:
          <span>{{ currencyFormat(fleteTotal) }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr>
                <th v-if="tipo != 'ver'" style=""></th>
                <th style="">Conceptos</th>
                <th style="">Multipicador</th>
                <th class="derecha" style="">Costo Unitario</th>
                <th style="">Sub Total</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(costo, i) in costos.filter(
                  (v) =>
                    v.esorigenflag == 1 && v.esventaflag == 1 && v.status == 1
                )"
                :key="i"
              >
                <td v-if="tipo != 'ver'">
                  <v-btn
                    icon
                    color="red"
                    x-small
                    @click="costo.status = !costo.status && calcTotales()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>

                <td>
                  <v-text-field
                    class="widthTD"
                    dense
                    hide-details
                    v-model="costo.nameservice"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    class="widthTD"
                    :items="
                      multiplicador.filter(
                        (v) => v.code != 5 && v.code != 13 && v.code != 14
                      )
                    "
                    v-model="costo.id_multiplicador"
                    :readonly="tipo == 'ver'"
                    placeholder="Multiplicador"
                    hide-details
                    dense
                    @change="calcTotales"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    class="derecha tdMontos"
                    hide-details
                    dense
                    max-width="50%"
                    v-model="costo.costounitario"
                    :readonly="tipo == 'ver'"
                    prefix="$"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                  ></v-text-field>
                </td>
                <td>
                  {{
                    currencyFormat(
                      (multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      ).length > 0
                        ? multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          )[0].valor
                        : 0) *
                        costo.costounitario *
                        calcularFac(
                          multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          ).length > 0
                            ? multiplicador.filter(
                                (v) => v.id == costo.id_multiplicador
                              )[0].code
                            : "N",
                          datosPrincipales.metroscc,
                          datosPrincipales.kg,
                          datosPrincipales.containers,
                          datosPrincipales.amount
                        )
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
      class="condensed my-1"
      v-if="
        (services.filter((v) => v.codegroupservices == 3).length > 0
          ? services.filter((v) => v.codegroupservices == 3)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 5).length > 0
          ? services.filter((v) => v.codegroupservices == 5)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 9).length > 0
          ? services.filter((v) => v.codegroupservices == 9)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 10).length > 0
          ? services.filter((v) => v.codegroupservices == 10)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 11).length > 0
          ? services.filter((v) => v.codegroupservices == 11)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 12).length > 0
          ? services.filter((v) => v.codegroupservices == 12)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 13).length > 0
          ? services.filter((v) => v.codegroupservices == 13)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 16).length > 0
          ? services.filter((v) => v.codegroupservices == 16)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 17).length > 0
          ? services.filter((v) => v.codegroupservices == 17)[0].status
          : false)
      "
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{
            datosPrincipales.idshipment.idTransporte == 2
              ? "Gastos de Aerolínea: "
              : "Gastos Navieros: "
          }}

          <span>{{ currencyFormat(resumenOpcion.gasto) }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr>
                <th v-if="tipo != 'ver'" style=""></th>
                <th style="">Conceptos</th>
                <th style="">Multipicador</th>
                <th class="derecha" style="">Costo Unitario</th>
                <th style="">Sub Total</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(costo, i) in costos.filter(
                  (v) =>
                    v.eslocalflag == 1 && v.esventaflag == 1 && v.status == 1
                )"
                :key="i"
              >
                <td v-if="tipo != 'ver'">
                  <v-btn
                    icon
                    color="red"
                    x-small
                    @click="costo.status = !costo.status && calcTotales()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>

                <td>
                  <v-text-field
                    class="widthTD"
                    dense
                    hide-details
                    v-model="costo.nameservice"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    class="widthTD"
                    :items="multiplicador"
                    v-model="costo.id_multiplicador"
                    :readonly="tipo == 'ver'"
                    placeholder="Multiplicador"
                    hide-details
                    dense
                    @change="calcTotales"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 5 &&
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 13 &&
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 14
                    "
                    hide-details
                    dense
                    max-width="50%"
                    v-model="costo.costounitario"
                    prefix="$"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 13
                    "
                    hide-details
                    max-width="50%"
                    dense
                    max="100"
                    v-model="costo.cif"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                    suffix="%"
                  ></v-text-field>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 14 ||
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 5
                    "
                    hide-details
                    max-width="50%"
                    dense
                    max="100"
                    v-model="costo.seguro"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                    suffix="%"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                </td>
                <td
                  v-if="
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 5 &&
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 13 &&
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 14
                  "
                >
                  {{
                    currencyFormat(
                      (multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      ).length > 0
                        ? multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          )[0].valor
                        : 0) *
                        costo.costounitario *
                        calcularFac(
                          multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          ).length > 0
                            ? multiplicador.filter(
                                (v) => v.id == costo.id_multiplicador
                              )[0].code
                            : "N",
                          datosPrincipales.metroscc,
                          datosPrincipales.kg,
                          datosPrincipales.containers,
                          datosPrincipales.amount
                        )
                    )
                  }}
                </td>

                <td
                  v-if="
                    multiplicador.filter((v) => v.id == costo.id_multiplicador)
                      .length > 0
                      ? multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 5 ||
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 13 ||
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 14
                      : false
                  "
                >
                  {{
                    currencyFormat(
                      calcularValor(
                        datosPrincipales.amount,
                        fleteTotal,
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        ).length > 0
                          ? multiplicador.filter(
                              (v) => v.id == costo.id_multiplicador
                            )[0].code
                          : "",
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? costo.cif
                            : costo.seguro
                          : 0
                      )
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
      class="condensed my-1"
      v-if="
        (services.filter((v) => v.codegroupservices == 2).length > 0
          ? services.filter((v) => v.codegroupservices == 2)[0].status
          : false) ||
        (services.filter((v) => v.codegroupservices == 6).length > 0
          ? services.filter((v) => v.codegroupservices == 6)[0].status
          : false)
      "
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Aduanas:
          <span>{{ currencyFormat(resumenOpcion.aduana) }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr>
                <th v-if="tipo != 'ver'" style=""></th>
                <th style="">Conceptos</th>
                <th style="">Multipicador</th>
                <th class="derecha" style="">Costo Unitario</th>
                <th style="">Sub Total</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(costo, i) in costos.filter(
                  (v) =>
                    v.esaduanaflag == 1 && v.esventaflag == 1 && v.status == 1
                )"
                :key="i"
              >
                <td v-if="tipo != 'ver'">
                  <v-btn
                    icon
                    color="red"
                    x-small
                    @click="costo.status = !costo.status && calcTotales()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>

                <td>
                  <v-text-field
                    class="widthTD"
                    dense
                    hide-details
                    v-model="costo.nameservice"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    class="widthTD"
                    :items="multiplicador"
                    v-model="costo.id_multiplicador"
                    :readonly="tipo == 'ver'"
                    placeholder="Multiplicador"
                    hide-details
                    dense
                    @change="calcTotales"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 5 &&
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 13 &&
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 14
                    "
                    hide-details
                    dense
                    max-width="50%"
                    v-model="costo.costounitario"
                    :readonly="tipo == 'ver'"
                    prefix="$"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                  ></v-text-field>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 13
                    "
                    hide-details
                    max-width="50%"
                    suffix="%"
                    dense
                    max="100"
                    v-model="costo.cif"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                  <v-text-field
                    max-width="50%"
                    suffix="%"
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 14 ||
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 5
                    "
                    hide-details
                    dense
                    max="100"
                    v-model="costo.seguro"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                </td>

                <td
                  v-if="
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 5 &&
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 13 &&
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 14
                  "
                >
                  {{
                    currencyFormat(
                      (multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      ).length > 0
                        ? multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          )[0].valor
                        : 0) *
                        costo.costounitario *
                        calcularFac(
                          multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          ).length > 0
                            ? multiplicador.filter(
                                (v) => v.id == costo.id_multiplicador
                              )[0].code
                            : "N",
                          datosPrincipales.metroscc,
                          datosPrincipales.kg,
                          datosPrincipales.containers,
                          datosPrincipales.amount
                        )
                    )
                  }}
                </td>

                <td
                  v-if="
                    multiplicador.filter((v) => v.id == costo.id_multiplicador)
                      .length > 0
                      ? multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 5 ||
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 13 ||
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 14
                      : false
                  "
                >
                  {{
                    currencyFormat(
                      calcularValor(
                        datosPrincipales.amount,
                        fleteTotal,
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        ).length > 0
                          ? multiplicador.filter(
                              (v) => v.id == costo.id_multiplicador
                            )[0].code
                          : "",
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? costo.cif
                            : costo.seguro
                          : 0
                      )
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
      class="condensed my-1"
      v-if="
        services.filter((v) => v.codegroupservices == 7).length > 0
          ? services.filter((v) => v.codegroupservices == 7)[0].status
          : false
      "
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Almacén:
          <span>{{ currencyFormat(resumenOpcion.almacen) }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr>
                <th v-if="tipo != 'ver'" style=""></th>
                <th style="">Conceptos</th>
                <th style="">Multipicador</th>
                <th class="derecha" style="">Costo Unitario</th>
                <th style="">Sub Total</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(costo, i) in costos.filter(
                  (v) =>
                    v.esalmacenflag == 1 && v.esventaflag == 1 && v.status == 1
                )"
                :key="i"
              >
                <td v-if="tipo != 'ver'">
                  <v-btn
                    icon
                    color="red"
                    x-small
                    @click="costo.status = !costo.status && calcTotales()"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>

                <td>
                  <v-text-field
                    class="widthTD"
                    dense
                    hide-details
                    v-model="costo.nameservice"
                    :readonly="tipo == 'ver'"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    class="widthTD"
                    :items="multiplicador"
                    v-model="costo.id_multiplicador"
                    :readonly="tipo == 'ver'"
                    placeholder="Multiplicador"
                    hide-details
                    dense
                    @change="calcTotales"
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 5 &&
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 13 &&
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code != 14
                    "
                    hide-details
                    dense
                    max-width="50%"
                    v-model="costo.costounitario"
                    :readonly="tipo == 'ver'"
                    prefix="$"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                  ></v-text-field>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 13
                    "
                    hide-details
                    max-width="50%"
                    dense
                    max="100"
                    v-model="costo.cif"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    :readonly="tipo == 'ver'"
                    @keyup="calcTotales"
                  ></v-text-field>
                  <v-text-field
                    class="derecha tdMontos"
                    v-if="
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 14 ||
                      multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      )[0].code == 5
                    "
                    hide-details
                    max-width="50%"
                    dense
                    max="100"
                    v-model="costo.seguro"
                    :readonly="tipo == 'ver'"
                    type="number"
                    :min="costo.minimo"
                    step="0.01"
                    @keyup="calcTotales"
                  ></v-text-field>
                </td>
                <td
                  v-if="
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 5 &&
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 13 &&
                    multiplicador.filter(
                      (v) => v.id == costo.id_multiplicador
                    )[0].code != 14
                  "
                >
                  {{
                    currencyFormat(
                      (multiplicador.filter(
                        (v) => v.id == costo.id_multiplicador
                      ).length > 0
                        ? multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          )[0].valor
                        : 0) *
                        costo.costounitario *
                        calcularFac(
                          multiplicador.filter(
                            (v) => v.id == costo.id_multiplicador
                          ).length > 0
                            ? multiplicador.filter(
                                (v) => v.id == costo.id_multiplicador
                              )[0].code
                            : "N",
                          datosPrincipales.metroscc,
                          datosPrincipales.kg,
                          datosPrincipales.containers,
                          datosPrincipales.amount
                        )
                    )
                  }}
                </td>

                <td
                  v-if="
                    multiplicador.filter((v) => v.id == costo.id_multiplicador)
                      .length > 0
                      ? multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 5 ||
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 13 ||
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        )[0].code == 14
                      : false
                  "
                >
                  {{
                    currencyFormat(
                      calcularValor(
                        datosPrincipales.amount,
                        fleteTotal,
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        ).length > 0
                          ? multiplicador.filter(
                              (v) => v.id == costo.id_multiplicador
                            )[0].code
                          : "",
                        multiplicador.filter(
                          (v) => v.id == costo.id_multiplicador
                        ).length > 0
                          ? [0].code == 14
                            ? costo.cif
                            : costo.seguro
                          : 0
                      )
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--  -->

    <v-dialog v-model="dialogVenta" max-width="600px">
      <v-card>
        <v-card-title primary-title>
          <h3>Nuevo Costo Venta :</h3>
        </v-card-title>
        <v-card-text>
          <v-form ref="nuevoCostoVenta">
            <div class="row">
              <div class="col-md-3">
                <v-autocomplete
                  dense
                  autocomplete="none"
                  :items="opciones"
                  label="Tipo"
                  :value="opciones.id"
                  v-model="fromDataService.idOpcion"
                  :rules="[(v) => !!v || 'Dato requerido']"
                >
                </v-autocomplete>
              </div>
              <div class="col-md-3">
                <v-autocomplete
                  dense
                  label="Multiplicador"
                  :items="multiplicador"
                  v-model="fromDataService.id_multiplicador"
                >
                </v-autocomplete>
              </div>
              <div class="col-md-3">
                <v-text-field
                  dense
                  label="Concepto"
                  :rules="[(v) => !!v || 'Dato requerido']"
                  v-model="fromDataService.nameservice"
                >
                </v-text-field>
              </div>
              <div class="col-md-2">
                <v-text-field
                  dense
                  label="Precio"
                  type="number"
                  min="0"
                  :rules="[(v) => !!v || 'Dato requerido']"
                  v-model="fromDataService.costounitario"
                >
                </v-text-field>
              </div>
              <div class="col-md-12">
                <v-flex text-right>
                  <v-btn
                    small
                    outlined
                    color="success"
                    @click="guardarCostoVenta"
                  >
                    Aceptar
                  </v-btn>
                </v-flex>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  props: ["tipo"],
  mixins: [funcion],
  data() {
    return {
      fleteTotal: 0,
      dialog: false,
      dialogVenta: false,
      panel: [0],
      opciones: [
        {
          id: "FGO",
          value: "FGO",

          text: " Flete y Gastos de origen",
        },
        {
          id: "GLN",
          value: "GLN",
          text: "Gastos Locales Navieros",
        },
        {
          id: "AS",
          value: "AS",

          text: "Aduanas",
        },
        {
          id: "AL",
          value: "AL",

          text: "Almacén",
        },
      ],

      items: [],
      totalOption: 0.0,
      totalVenta: 0,
      resumenOpcion: {
        flete: 0,
        gasto: 0,
        aduana: 0,
        amacen: 0,
      },
      resumenVenta: {
        flete: 0,
        gasto: 0,
        aduana: 0,
        almacen: 0,
      },
      fromDataService: {
        esventaflag: 1,
        status: 1,
        id_begend: 0,
        costounitario: "",
        esorigenflag: 0,
        eslocalflag: 0,
        esaduanaflag: 0,
        esalmacenflag: 0,
        esopcionflag: 0,
        seguro: 0,
        cif: 0,
      },
    };
  },
  mounted() {
    this.calcTotales();
  },
  methods: {
    ...mapMutations([
      "updateDatosNotas",
      "setCostos",
      "setTotalVenta",
      "setFleteTotal",
    ]),
    dialogSetFrom() {
      this.fromDataService = {
        esventaflag: 1,
        status: 1,
        id_begend: 0,
        costounitario: "",
        esorigenflag: 0,
        eslocalflag: 0,
        esaduanaflag: 0,
        esalmacenflag: 0,
        esopcionflag: 0,
        seguro: 0,
        cif: 0,
      };
      this.dialogVenta = true;
    },
    guardarCostoVenta() {
      if (this.$refs.nuevoCostoVenta.validate()) {
        switch (this.fromDataService.idOpcion) {
          case "FGO":
            this.fromDataService.esorigenflag = 1;
            break;
          case "GLN":
            this.fromDataService.eslocalflag = 1;

            break;
          case "AS":
            this.fromDataService.esaduanaflag = 1;

            break;
          case "AL":
            this.fromDataService.esalmacenflag = 1;

            break;
        }
        this.costos.push(this.fromDataService);
        this.setCostos(this.costos);
        this.calcTotales();

        this.dialogVenta = false;
      }
    },
    calcTotales() {
      this.totalOption = 0;
      this.resumenOpcion = { gasto: 0, aduana: 0, almacen: 0 };
      this.fleteTotal = 0;
      if (
        this.services.filter((v) => v.codegroupservices == 4).length > 0
          ? this.services.filter((v) => v.codegroupservices == 4)[0].status
          : false
      ) {
        this.costos
          .filter(
            (v) => v.esorigenflag == 1 && v.esventaflag == 1 && v.status == 1
          )
          .forEach((element) => {
            //  FLETE
            if (
              this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0].code != 5 &&
              this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0].code != 13 &&
              this.multiplicador.filter(
                (v) => v.id == element.id_multiplicador
              )[0].code != 14
            ) {
              this.fleteTotal +=
                (this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                ).length > 0
                  ? this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    )[0].valor
                  : 0) *
                element.costounitario *
                this.calcularFac(
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code
                    : "N",
                  this.datosPrincipales.metroscc,
                  this.datosPrincipales.kg,
                  this.datosPrincipales.containers,
                  this.datosPrincipales.amount
                );
            }
          });
      }
      this.setFleteTotal(this.fleteTotal);
      this.calcularTotalesNoFlete(this.fleteTotal);
    },

    calcularTotalesNoFlete() {
      this.resumenOpcion = { gasto: 0, aduana: 0, almacen: 0 };
      this.costos
        .filter((v) => v.esventaflag == 1 && v.status == 1)
        .forEach((element) => {
          //   GASTOS
          if (
            (this.services.filter((v) => v.codegroupservices == 3).length > 0
              ? this.services.filter((v) => v.codegroupservices == 3)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 5).length > 0
              ? this.services.filter((v) => v.codegroupservices == 5)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 9).length > 0
              ? this.services.filter((v) => v.codegroupservices == 9)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 10).length > 0
              ? this.services.filter((v) => v.codegroupservices == 10)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 11).length > 0
              ? this.services.filter((v) => v.codegroupservices == 11)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 12).length > 0
              ? this.services.filter((v) => v.codegroupservices == 12)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 13).length > 0
              ? this.services.filter((v) => v.codegroupservices == 13)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 16).length > 0
              ? this.services.filter((v) => v.codegroupservices == 16)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 17).length > 0
              ? this.services.filter((v) => v.codegroupservices == 17)[0].status
              : false)
          ) {
            if (element.eslocalflag == 1) {
              if (
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 5 &&
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 13 &&
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 14
              ) {
                this.resumenOpcion.gasto +=
                  (this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor
                    : 0) *
                  element.costounitario *
                  this.calcularFac(
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "N",
                    this.datosPrincipales.metroscc,
                    this.datosPrincipales.kg,
                    this.datosPrincipales.containers,
                    this.datosPrincipales.amount
                  );
              } else {
                this.resumenOpcion.gasto += this.calcularValor(
                  this.datosPrincipales.amount,
                  this.fleteTotal,
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code
                    : "",
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? [0].code == 14
                      ? element.cif
                      : element.seguro
                    : 0
                );
              }
            }
          }
          //   ADUANA
          if (
            (this.services.filter((v) => v.codegroupservices == 2).length > 0
              ? this.services.filter((v) => v.codegroupservices == 2)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 6).length > 0
              ? this.services.filter((v) => v.codegroupservices == 6)[0].status
              : false)
          ) {
            if (element.esaduanaflag == 1) {
              if (
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 5 &&
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 13 &&
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 14
              ) {
                this.resumenOpcion.aduana +=
                  (this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor
                    : 0) *
                  element.costounitario *
                  this.calcularFac(
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "N",
                    this.datosPrincipales.metroscc,
                    this.datosPrincipales.kg,
                    this.datosPrincipales.containers,
                    this.datosPrincipales.amount
                  );
              } else {
                this.resumenOpcion.aduana += this.calcularValor(
                  this.datosPrincipales.amount,
                  this.fleteTotal,
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code
                    : "",
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? [0].code == 14
                      ? element.cif
                      : element.seguro
                    : 0
                );
              }
            }
          }
          //   ALMACEN
          if (
            this.services.filter((v) => v.codegroupservices == 7).length > 0
              ? this.services.filter((v) => v.codegroupservices == 7)[0].status
              : false
          ) {
            if (element.esalmacenflag == 1) {
              if (
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 5 &&
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 13 &&
                this.multiplicador.filter(
                  (v) => v.id == element.id_multiplicador
                )[0].code != 14
              ) {
                this.resumenOpcion.almacen +=
                  (this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].valor
                    : 0) *
                  element.costounitario *
                  this.calcularFac(
                    this.multiplicador.filter(
                      (v) => v.id == element.id_multiplicador
                    ).length > 0
                      ? this.multiplicador.filter(
                          (v) => v.id == element.id_multiplicador
                        )[0].code
                      : "N",
                    this.datosPrincipales.metroscc,
                    this.datosPrincipales.kg,
                    this.datosPrincipales.containers,
                    this.datosPrincipales.amount
                  );
              } else {
                this.resumenOpcion.almacen += this.calcularValor(
                  this.datosPrincipales.amount,
                  this.fleteTotal,
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? this.multiplicador.filter(
                        (v) => v.id == element.id_multiplicador
                      )[0].code
                    : "",
                  this.multiplicador.filter(
                    (v) => v.id == element.id_multiplicador
                  ).length > 0
                    ? [0].code == 14
                      ? element.cif
                      : element.seguro
                    : 0
                );
              }
            }
          }
        });
      this.calcTotal();
    },
    calcTotal() {
      this.totalOption = 0;
      this.totalOption =
        this.fleteTotal +
        this.resumenOpcion.gasto +
        this.resumenOpcion.aduana +
        this.resumenOpcion.almacen;
      let valores = {
        flete: this.fleteTotal,
        gasto: this.resumenOpcion.gasto,
        aduana: this.resumenOpcion.aduana,
        almacen: this.resumenOpcion.almacen,
      };

      this.setTotalVenta(valores);
    },
  },
  computed: {
    ...mapState([
      "datosPrincipales",
      "begEndList",
      "roles",
      "multiplicador",
      "costos",
      "factor",
      "services",
      "stepCosto",
    ]),
  },
  watch: {
    stepCosto() {
      this.calcTotales();
    },
  },
};
</script>

<style scoped>
.v-expansion-panels.condensed .v-expansion-panel-header {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: auto;
}
.widthTD {
  width: 150px !important;
}

/* .tdMontos {
  width:  !important; 
} */

td {
  padding: 0 3px !important;
  font-size: 5rem;
}
.derecha {
  text-align: right !important;
  align-content: right !important;
}
.v-text-field__slot {
  max-width: 5px !important;
}

table td:nth-child(5) {
  align-content: right !important;
  text-align: right !important;
  max-width: 90px !important;
  min-width: 90px !important;
}
table td:nth-child(6) {
  align-content: right !important;
  text-align: right !important;
  max-width: 90px !important;
  min-width: 90px !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 2px 0 2px !important;
}
</style>
